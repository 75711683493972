@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url("../videoCss/video-react.css");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f9f8f9;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  color: #999999;
}

p {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: justify;
  font-family: "Poppins", sans-serif;
}

.heading-title {
  font-family: "Poppins", sans-serif;
  color: #222222;
  font-size: 36px;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 27px;
  text-transform: capitalize;
  text-align: center;
}

.icon-title {
  font-family: "Poppins", sans-serif;
  color: #222222;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 13px;
  text-transform: capitalize;
}

.icons {
  margin-bottom: 35px;
  width: 100px;
  height: 100px;
}

.content-wrapper {
  height: 100%;
}

.sec-space {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.pos-rel {
  position: relative;
}

.bg-white {
  background-color: #fff;
}

.app-btn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1030;
  box-shadow: 3px 17px 26px -4px rgba(0, 0, 0, 0.4);
  transition: all ease-in-out 300ms;
  border-radius: 50px;
  background: inherit;
}
.app-btn img {
  height: 60px;
  cursor: pointer;
}
.app-btn h3 {
  font-weight: 500;
  text-align: center;
  margin-right: 10px;
  align-self: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid #222222;
  background: #fff;
  color: #222222;
  padding: 10px;
  font-size: 15px;
  border-radius: 3px;
  text-align: center;
  position: absolute;
  top: -55px;
  right: -37px;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.2);
  width: 117px;
}

/* Header */
.header {
  background-color: inherit;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 75px;
}
.header .logo-box .head-logo {
  height: 65px;
  margin-top: 7px;
}
.header .nav-top {
  position: relative;
}
.header .nav {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  overflow: hidden;
  padding: 14px;
  width: 100%;
  z-index: 1000;
  max-height: 0;
  transition: max-height 0.5s ease-out;
}
.header .menu {
  margin-bottom: 0;
}
.header .menu li {
  list-style: none;
  padding: 10px 0;
}
.header .menu li .link {
  display: block;
  padding: 15px 20px;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.header .menu li:hover {
  opacity: 1;
  transition: 0.4s ease-in;
}
.header .menu li:hover .link {
  color: #00B7ED;
}
.header .hamb {
  cursor: pointer;
  float: right;
  padding: 40px 20px;
  text-align: right;
}
.header .hamb .hamb-line {
  background: #00B7ED;
  display: block;
  height: 2.5px;
  position: relative;
  width: 27px;
}
.header .hamb .hamb-line::after, .header .hamb .hamb-line::before {
  background: #00B7ED;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}
.header .hamb .hamb-line::before {
  top: 5px;
}
.header .hamb .hamb-line::after {
  top: -5px;
}
.header .side-menu {
  display: none;
}
.header .side-menu:checked ~ nav {
  max-height: 100% !important;
}
.header .side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}
.header .side-menu:checked ~ .hamb .hamb-line::before {
  transform: rotate(-45deg);
  top: 0;
}
.header .side-menu:checked ~ .hamb .hamb-line::after {
  transform: rotate(45deg);
  top: 0;
}

.header.scrolled {
  position: fixed;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px #d8d1d1;
}
.header.scrolled .menu li .link {
  color: #222222;
}

/* Home */
.home-wrap {
  position: relative;
}
.home-wrap .video-banner .video-react-control-bar {
  display: none;
}
.home-wrap .video-banner .video-react-video {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.home-wrap .video-banner .video-react .video-react-big-play-button {
  display: none;
  opacity: 0;
}
.home-wrap .video-banner .text-box {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
.home-wrap .video-banner .text-box h1, .home-wrap .video-banner .text-box p {
  text-align: center;
  color: #ffffff;
}

/* About */
.about-wrap .abt-img {
  width: 100%;
}
.about-wrap .text-box .lft-box {
  padding-right: 7rem;
  padding-top: 3rem;
}

.visi-wrap .text-box .rit-box {
  padding-top: 3rem;
  padding-left: 5rem;
}

/* Project */
.proj-wrap .gallery-sec img {
  width: 100%;
  height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
}
.proj-wrap .gallery-sec .lb-canvas .lb-img {
  width: auto;
  height: auto;
  -o-object-fit: unset;
     object-fit: unset;
}
.proj-wrap .gallery-sec .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 10;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(55, 140, 207, 0.87);
}
.proj-wrap .gallery-sec .overlay .text-box {
  display: inline-block;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 26px;
}
.proj-wrap .gallery-sec .overlay .text-box img {
  width: auto;
  height: auto;
  margin-bottom: 10px;
  -o-object-fit: unset;
     object-fit: unset;
}
.proj-wrap .gallery-sec .overlay .text-box h6 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
}
.proj-wrap .gallery-sec .pos-rel {
  margin: 20px 5px;
  transition: transform 0.5s ease-in-out;
}
.proj-wrap .gallery-sec .pos-rel:hover {
  transform: scale(1.03);
}
.proj-wrap .gallery-sec .pos-rel:hover .overlay {
  opacity: 1;
}
.proj-wrap .proj-txt {
  text-align: left;
  padding-top: 50px;
}
.proj-wrap .proj-txt h4 {
  margin-bottom: 35px;
}
.proj-wrap .proj-txt .card {
  border: 0;
  box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-left: 7px solid #00B7ED;
}

.team-wrap .react-multi-carousel-track,
.team-wrap .team-caros.react-multi-carousel-list {
  position: unset;
}
.team-wrap .react-multiple-carousel__arrow::before {
  color: #00B7ED;
}
.team-wrap .react-multiple-carousel__arrow {
  background: inherit;
}
.team-wrap .react-multiple-carousel__arrow:hover {
  background: inherit;
}
.team-wrap .react-multiple-carousel__arrow--left {
  left: -67px;
}
.team-wrap .react-multiple-carousel__arrow--right {
  right: -67px;
}
.team-wrap .team-box {
  display: flex;
  width: 90%;
  margin: auto;
}
.team-wrap .team-box img {
  width: 70px;
  height: 70px;
  margin-top: 1.3rem;
}
.team-wrap .team-box .text-box {
  display: inline-block;
  padding: 1rem;
}
.team-wrap .team-box .text-box p {
  font-size: 20px;
  line-height: 35px;
  color: #999999;
  font-family: "Lora", serif;
  font-style: italic;
  margin-bottom: 29px;
}
.team-wrap .team-box .text-box h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
  text-align: left;
}

.service-wrap .make-box {
  padding-bottom: 100px;
}
.service-wrap .make-box .make-bg {
  background-image: url(../images/serviceBg.jpg);
  background-size: cover;
  background-position: 100%;
  background-repeat: no-repeat;
  height: 955px;
  position: relative;
  background-color: rgba(52, 58, 63, 0.99);
  background-blend-mode: overlay;
}
.service-wrap .make-box .make-bg .heading-title {
  color: #fff;
  text-align: left;
  padding: 0;
  padding-top: 60px;
  font-size: 40px;
  margin: 0;
}
.service-wrap .make-box .make-bg .col-md-10.m-auto {
  width: 90%;
}
.service-wrap .make-box .make-bg .card-body li {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: justify;
  font-family: "Poppins", sans-serif;
}
.service-wrap .make-box .card-text {
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 500;
  color: #222222;
  font-size: 18px;
  line-height: 1.5;
}
.service-wrap .make-box .card-body {
  position: relative;
}
.service-wrap .make-box .card {
  border: 0;
  border-radius: 0;
  height: 545px;
}
.service-wrap .make-box .card img {
  width: 100%;
  height: 217px;
}
.service-wrap .make-box .card-grp {
  position: absolute;
  transition: all 0.9s ease-in-out;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 10px;
}
.service-wrap .make-box .card-grp:hover {
  transform: scale(1.1);
}
.service-wrap .make-box .g1 {
  top: 210px;
}
.service-wrap .make-box .g2 {
  top: 140px;
}
.service-wrap .make-box .g3 {
  top: 70px;
}
.service-wrap .make-box .g4 {
  top: 0;
}

.collab-wrap .heading-title,
.affil-wrap .heading-title,
.service-wrap .heading-title {
  margin-bottom: 50px;
}

.con-wrap .con-sec {
  display: grid;
  grid-template-columns: 5fr 5fr 3fr;
  text-align: center;
  justify-content: center;
  padding-bottom: 40px;
}
.con-wrap .con-box {
  text-align: left;
  padding: 0 21px;
}
.con-wrap .con-box.pl28 {
  padding-left: 28%;
}
.con-wrap .con-box.pl35 {
  padding-left: 35%;
}
.con-wrap .con-box img {
  height: 63px;
  margin-bottom: 25px;
}
.con-wrap .con-box h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 10px;
  color: #222222;
}
.con-wrap .con-box p {
  text-align: left;
}
.con-wrap .foot-sec {
  border-top: 1px solid #e5e5e5;
  padding-top: 2rem;
}
.con-wrap .foot-sec span {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-transform: capitalize;
}
.con-wrap .foot-sec .ico-box {
  display: flex;
  justify-content: flex-end;
}
.con-wrap .foot-sec .ico-box img {
  height: 18px;
  padding-left: 23px;
}

/* Responsive */
@media (max-width: 575px) {
  .video-react-video {
    width: 100%;
  }
  .home-wrap {
    height: 100%;
  }
  .about-wrap .text-box .lft-box {
    padding: 0 15px;
  }
  .visi-wrap .text-box .rit-box {
    padding: 20px 15px;
    padding-bottom: 0;
  }
  .icons {
    margin-bottom: 20px;
  }
  .icon-title {
    margin-bottom: 35px;
  }
  .team-wrap .team-box {
    display: block;
    width: 100%;
  }
  .service-wrap .make-box .card-grp {
    position: unset;
    margin-top: 40px;
    height: auto;
  }
  .service-wrap .make-box .g1 {
    height: auto;
  }
  .service-wrap .make-box .make-bg {
    height: auto;
    padding-bottom: 40px;
  }
  .proj-wrap .proj-txt .card {
    margin-bottom: 30px;
  }
  .con-wrap .con-sec {
    display: block;
  }
  .con-wrap .con-box,
  .con-wrap .con-box p {
    text-align: center;
  }
  .con-wrap .con-box.pl28,
  .con-wrap .con-box.pl35 {
    padding-left: 0;
  }
  .con-wrap .con-box {
    margin-bottom: 30px;
  }
  .con-wrap .foot-sec {
    text-align: center;
  }
  .con-wrap .foot-sec .row {
    flex-direction: column-reverse;
  }
  .con-wrap .foot-sec .ico-box {
    justify-content: center;
    margin-bottom: 23px;
  }
}
@media (min-width: 993px) {
  .header .hamb {
    opacity: 0;
    display: none;
  }
}
@media (min-width: 768px) {
  .header .nav {
    float: right;
    height: 70px;
    min-height: 70px;
    overflow: visible;
    padding: 0;
    text-align: right;
  }
  .header .menu li {
    float: left;
  }
}
@media screen and (max-width: 600px) {
  .header .nav {
    background: #333333;
    box-shadow: 1px 5px 7px 0 #bebbbb;
    height: auto;
    left: 0;
    margin: auto;
    opacity: 0.95;
    padding: 0;
    position: fixed;
    right: 0;
    top: 70px;
  }
  .header .nav .menu {
    padding-left: 0;
    width: 100%;
  }
  .header .nav .menu li .link {
    padding: 0 20px;
  }
  .header .hamb {
    padding: 26px 20px;
    display: block;
    opacity: 1;
  }
  .header.scrolled .menu li .link {
    color: #ffffff;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .side-menu:checked ~ nav {
    max-height: 100%;
  }
  .home-wrap {
    height: 100%;
  }
  .about-wrap .text-box .lft-box {
    padding: 0 15px;
  }
  .visi-wrap .text-box .rit-box {
    padding: 0 15px;
    padding-bottom: 0;
  }
  .icons {
    margin-bottom: 20px;
  }
  .icon-title {
    margin-bottom: 35px;
  }
  .team-wrap .team-box {
    display: block;
    width: 100%;
  }
  .service-wrap .make-box .card-grp {
    position: unset;
    margin-top: 40px;
  }
  .service-wrap .make-box .make-bg {
    height: auto;
    padding-bottom: 40px;
  }
  .proj-wrap .proj-txt .card {
    height: 100%;
  }
  .con-wrap .con-sec {
    grid-template-columns: 7fr 4fr 4fr;
  }
  .con-wrap .con-box {
    margin-bottom: 30px;
  }
  .header .hamb {
    display: none;
  }
  .header .menu li .link {
    padding: 14px;
  }
}/*# sourceMappingURL=style.css.map */